<!--
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-08-29 22:14:30
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-08-29 22:36:21
 * @FilePath: \settlement-frontend\src\components\layout\EmptyData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="empty-block">
    <img
      class="w-220px"
      src="media/illustrations/sigma-1/non-profit.png"
      alt=""
      title=""
    />
    <p>{{ $t("common.noData") }}</p>
  </div>
</template>
<style lang="scss" scoped>
.empty-block {
  padding-top: 144px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  p {
    margin-top: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;
  }
}
</style>
